import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SiteLink from '@components/SiteLink'
import './top-link.component.scss'

const TopLinkComponent = props => {
  return (
    <div className="top-link-wrapper">
      <SiteLink className="top-link-container" to={props.topLinkContent.link} tracking={props.topLinkContent.tracking}>
        <div className="top-link-content">
          <div className="page-name">{props.topLinkContent.pageName}</div>
          <FontAwesomeIcon className="arrow-right" icon="angle-right" />
        </div>
      </SiteLink>
    </div>
  )
}

TopLinkComponent.propTypes = {
  topLinkContent: PropTypes.object.isRequired
}

export default TopLinkComponent
