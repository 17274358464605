export const masonryOptions = {
  itemSelector: '.face',
  isResizeBound: !1,
  columnWidth: 146,
  gutter: 15
}

export const IMG_STATE = {
  NORMAL: 'normal',
  HOVER: 'hover',
  VIDEO: 'video',
  BIG_IMG: 'big-img'
}
