import React, { Component } from 'react'
import update from 'immutability-helper'
import Masonry from 'react-masonry-component'

import Layout from '@components/layout/Layout'
import TopLinkComponent from '@components/top-link/top-link.component'
import FaceComponent from '@components/faces-of-cataplexy/face.component'
import { videoLists } from '@components/video-library/videos'
import { trackGa } from '@components/GoogleTracking'
import { masonryOptions, IMG_STATE } from '@utils/faces.util'

import './cataplexy-patient-testimonials.scss'


class CataplexyPatientTestimonials extends Component {
  constructor(props) {
    super(props)
    this.currentVideoRef = {}
    this.state = {
      videos: videoLists.facesOfCataplexy.map((video, index) => {
        //add state specific information to videos
        video.state = IMG_STATE.NORMAL
        video.img = {
          reg: `/images/faces/${index + 1}.jpg`,
          active: `/images/faces/${index + 1}_active.jpg`,
          big: `/images/faces/${index + 1}_big.jpg`,
          imgAlt: `Patient experiences with cataplexy video ${index + 1}`
        }
        return video
      }),
      isMobile: false
    }
  }

  updateDimensions() {
    if (window.innerWidth < 480) {
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false })
    }
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  render() {
    const { isMobile } = this.state

    const faceImages = this.state.videos.map((video, index) => {
      console.log('video', video)
      console.log('index', index)

      return (
        <FaceComponent
          video={video}
          index={index}
          key={index}
          onMouseEnter={() => {
            this.onMouseEnter(index)
          }}
          onMouseLeave={() => {
            this.onMouseLeave(index)
          }}
          onClick={() => {
            this.playVideo(index, isMobile)
          }}
        />
      )
    })
    return (
      <Layout location={this.props.location}>
        <div className="faces-wrapper">
          <TopLinkComponent
            topLinkContent={{
              pageName: 'Recognizing The Symptoms',
              link: 'Identifying Narcolepsy',
              tracking: 'faces of cataplexy, click, recognizing the symptoms button - faces of cataplexy'
            }}
          />
          <div className="primary-container">
            <h1 className="main-page-header">The Many Faces of Cataplexy</h1>
            <p className="body-copy-large">
              You can learn more about cataplexy from listening to real patients telling their stories. These descriptions
              of the various possible presentations of cataplexy may help you more effectively screen your own patients
              for this narcolepsy symptom.
            </p>
            <h2 className="video-note">Choose a patient to learn more about his or her experiences with cataplexy.</h2>
            <div className="video-wrapper">
              {!isMobile && (
                <div id="faces" ref={element => (this.videoDiv = element)}>
                  <Masonry
                    className={'faces'}
                    elementType={'div'}
                    options={masonryOptions}
                    ref={el => (this.masonry = this.masonry || el.masonry)}
                  >
                    {faceImages}
                  </Masonry>
                </div>
              )}
              {isMobile && <div className="faces-mobile">{faceImages}</div>}
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  onMouseEnter(videoPos) {
    if (this.state.videos[videoPos].state === IMG_STATE.NORMAL) {
      this.setState(previousState => {
        return {
          videos: update(previousState.videos, {
            [videoPos]: { state: { $set: IMG_STATE.HOVER } }
          })
        }
      })
    }
  }

  onMouseLeave(videoPos) {
    if (this.state.videos[videoPos].state === IMG_STATE.HOVER) {
      this.setState(previousState => {
        return {
          videos: update(previousState.videos, {
            [videoPos]: { state: { $set: IMG_STATE.NORMAL } }
          })
        }
      })
    }
  }

  playVideo(videoPos, isMobile) {
    this.setState(
      previousState => {
        let updatedVideos = {}
        previousState.videos.forEach((video, index) => {
          updatedVideos[index] = {
            state: { $set: IMG_STATE.NORMAL }
          }
        })
        let newState = IMG_STATE.VIDEO
        if (!previousState.videos[videoPos].videoId) newState = IMG_STATE.BIG_IMG
        updatedVideos = {
          ...updatedVideos,
          [videoPos]: { state: { $set: newState } }
        }

        // Track clicking on the static images too, the videos are handled
        // by the Brightcove player on play event but there is nothing in
        // place for the static images. Let's track them here, seems as good
        // as place as any.
        let video = previousState.videos[videoPos]
        if(video.tracking && !video.videoId) {
          trackGa(video.tracking)
        }

        return {
          videos: update(previousState.videos, updatedVideos)
        }
      },
      () => {
        if (!isMobile) this.swapLastVideos(videoPos)
      }
    )
  }

  /**
   * returns an immutability helper array with last three videos in the list with the items switched
   */
  getSpliceFromVideos(updatedVideos) {
    let spliceArray = [12, 3]
    spliceArray = spliceArray.concat(updatedVideos.slice(updatedVideos.length - 3))
    return spliceArray
  }

  swapLastVideos(videoPos) {
    if (this.state.videos.length - videoPos <= 2) {
      this.setState(prevState => {
        const videos = prevState.videos
        let tempVid = videos[videos.length - 3]
        videos[videos.length - 3] = videos[videoPos]
        videos[videoPos] = tempVid
        return {
          videos: update(prevState.videos, { $splice: [this.getSpliceFromVideos(prevState.videos)] })
        }
      })
    }
  }
}

export default CataplexyPatientTestimonials
